import React, { Component } from 'react';
import MainImage from './main.jpg';
// import NoticeImage from './fathersday.jpeg';
import { Container, Row, Col, Image, Modal, Button, Form } from 'react-bootstrap';
import { pageView, initGA } from '../Tracking';
import './Home.css';
import { Formik } from 'formik';
import * as Yup from 'yup';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = { 
        show: false,
        notice_show: false,
     };
  }
  componentDidMount() {
    initGA();
    pageView('/', 'Home Page')
    if(!(sessionStorage.getItem('donotshow') || localStorage.getItem('subscribed'))) {
      this.setState({ show: true });
    }
    if(!sessionStorage.getItem('noticedonotshow')) {
      this.setState({ notice_show: true })
    }
  }
  handleClose = () => {
    sessionStorage.setItem('donotshow', true);
    this.setState({ show: false });
  }
  handleNoticeClose = () => {
    sessionStorage.setItem('noticedonotshow', true);
    this.setState({ notice_show: false })
  }
  render() {
    return (
      <Container>
        <Row>
          <Col><Image src={MainImage} rounded fluid /></Col>
        </Row>
        <Row>
          <Col className="home-youtube-container">
            <iframe className="home-youtube" title="home-video" src="https://www.youtube.com/embed/f_vvzt3f548" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
          </Col>
        </Row>
        <Row>
          <Col className="py-4"><h2>Philosophy</h2></Col>
        </Row>
        <Row>
          <Col><p>Jacob Young is a high end men’s formal clothier brand that provides timeless, functional, and sophisticated custom made suits.  We offer variety of selections to choose from, and we are here to help you make decisions throughout a personalized process.  From selecting fabric to final fitting, we carefully guide you through each step for one of a kind garment that is truly yours.  Our garment is meant to conceal or minimize the look of any body shape concerns you may have in addition to enhancing your executive presence or personal brand.  We take high pride on our excellent client service and craftsmanship from the years of dedication and accumulated knowledge.  Get ready to be impressed by the excellence at Jacob Young. </p></Col>
        </Row>
        <Modal className="signup-modal" show={this.state.show} onHide={this.handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered >
          <Modal.Header closeButton>
            <Modal.Title>Sign up for our emailing list!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              validationSchema={
              Yup.object({
                email: Yup.string()
                  .email('Invalid email address')
                  .required('Email is required'),
                  firstName: Yup.string()
                  .required('First Name is required'),
                  lastName: Yup.string()
                  .required('Last Name is required'),
                })
              }
              onSubmit={
                (form) => {
                    fetch(`${process.env.REACT_APP_API}/subscribe`, {
                    method: 'POST',
                    headers: {
                      'Accept': 'application/json',
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(form)
                  })
                  .then(
                    () => {
                      this.setState({ show: false })
                      localStorage.setItem('subscribed', true);
                    }
                  )
                  .catch(error => {
                    this.props.handleError()
                  })
                }
              }
              initialValues={{email:'', firstName:'', lastName:''}}
              >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
                validateForm,
              }) => (
              <Form noValidate onSubmit={handleSubmit}>
                We are currently offering $100 (purchase of $990 or more) promotion when you sign up for our emailing list!
                  <Row>
                    <Col md={3}/>
                    <Col md={6}>
                      <Form.Row>
                        <Form.Group as={Col} controlId="formFirstName">
                          <Form.Control type="text"
                            name="firstName"
                            placeholder="First Name"
                            value={values.firstName}
                            onChange={handleChange}
                            isInvalid={errors.firstName} />
                          <Form.Control.Feedback type="invalid">
                            {errors.firstName}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} controlId="formLastName">
                          <Form.Control type="text"
                            name="lastName"
                            placeholder="Last Name"
                            value={values.lastName}
                            onChange={handleChange}
                            isInvalid={errors.lastName} />
                          <Form.Control.Feedback type="invalid">
                            {errors.lastName}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Form.Row>
                      <Form.Row>
                        <Form.Group as={Col} controlId="formEmail">
                          <Form.Control 
                            type="email" 
                            placeholder="Enter email address"
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            isInvalid={errors.email} />
                          <Form.Control.Feedback type="invalid">
                              {errors.email}
                            </Form.Control.Feedback>
                        </Form.Group>
                      </Form.Row>
                    </Col>
                    <Col md={3}/>
                  </Row>
                <Button variant="primary" type="submit">
                  Submit
                </Button>
              </Form>
            )}
          </Formik>
          </Modal.Body>
        </Modal>
      </Container>
    );
  }
}

export default Home;
